import dayjs from 'dayjs';
import { FC, ReactNode, useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasEmptyOrNull, notify, useAppDispatch } from '@/utils';
import { IProject, Phase, useProjects } from '@/hooks';
import { CreateProjectContext } from './context';
import { CreateProjectContextType, ICreateProject, ICreateProjectSendData } from './types';
import { projectActions } from '@/store/project/slice';
import { ICheckProjectName } from '@/hooks/fetch-hooks/use-projects/types';
import { useAuthContext } from '../auth';
import { FIT_ONLY } from '@/map/map/constants';

const initialPhase: Phase = {
	name: '',
	description: '<p>Adaugă descriere</p>',
	start: dayjs().valueOf(),
	end: dayjs().add(1, 'day').valueOf(),
	id: Date.now(),
};

const initialObj: ICreateProject = {
	description: '<p>Adaugă descriere</p>',
	name: '',
	stage: 'in progress',
	status: 'draft',
	type: undefined,
	phases: [initialPhase],
	end: dayjs().add(1, 'day').valueOf(),
	start: dayjs().valueOf(),
	elements: [],
	gallery: []
};

export const CreateProjectProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	// Extract the projectId from the query parameters
	const searchParams = new URLSearchParams(location.search);
	const projectId = searchParams.get('projectId');
	const { user } = useAuthContext();
	const { data: projData, createProject, updateProject,
		loadingUpdate, loadingCTA, checkProjectName: handleName, checkProjectGeometry
	} = useProjects<IProject>(false, projectId || undefined);
	const isLoading = useMemo(() => loadingUpdate || loadingCTA, [loadingUpdate, loadingCTA]);

	const initialProject = useMemo(() => {
		if (!projData) {
			return initialObj;
		}

		const updatedPhases = projData?.phases?.map((phase, index) => ({
			...phase,
			id: index + 1
		}));

		return {
			description: projData.description,
			name: projData.name,
			stage: projData.stage,
			status: projData.status,
			type: projData.type,
			phases: updatedPhases,
			end: projData.end,
			start: projData.start,
			elements: projData.elements || [],
			gallery: projData.gallery || []
		};
	}, [projData]);

	const isEditing: boolean = useMemo(() => !!projectId, [projectId]);
	const [step, setStep] = useState<number>(0);
	const [data, setData] = useState<ICreateProject>(isEditing ? initialProject : initialObj);
	const [nameError, setNameErroer] = useState<string | undefined>(undefined);
	const [interestFsp, setInterestFsp] = useState<string | undefined>(undefined);
	const [hasPhases, setHasPhases] = useState<boolean>(false);
	const [isDrawing, setIsDrawing] = useState<boolean>(false);
	// Determine if the current mode is editing

	const disbleEditing = useMemo(() =>
		JSON.stringify(initialProject) === JSON.stringify(data), [initialProject, data]);

	useEffect(() => {
		// Ensure we don't overwrite with an identical object
		if (isEditing && JSON.stringify(data) !== JSON.stringify(initialProject)) {
			setData(initialProject);
		}
		// eslint-disable-next-line
	}, [initialProject, isEditing]);

	useEffect(() => {
		if (!isEditing && data?.phases?.length === 1) {

			setData((prev) => ({
				...prev,
				// phases: prev.phases?.map(p => ({
				// 	...p,
				// 	start: prev.start,
				// 	end: prev.end,
				// }))
				phases: prev?.phases?.length === 1 ? [{
					...prev?.phases[0],
					start: prev.start,
					end: prev.end,
				}] : prev?.phases
			}));
		}

		if (user?.role !== 'fsp' && projData?.fsp?._id && isEditing) {
			setInterestFsp(projData?.fsp?._id);
		}
		// eslint-disable-next-line
	}, [isEditing, data]);

	useEffect(() => {
		if (user?.role !== 'fsp' && projData?.fsp?._id && isEditing) {
			setInterestFsp(projData?.fsp?._id);
		} else {
			setInterestFsp(undefined);
		}
		// eslint-disable-next-line
	}, [isEditing, projData, user?.role]);

	const handleAddPhase = (): void => {
		setData((prev) => ({
			...prev,
			phases: [...prev?.phases || [], {
				...initialPhase,
				id: Date.now(),
				start: prev?.start,
				end: prev?.end,
			}]
		}));
	};

	const handleRemovePhase = (index: number): void => {
		setData((prev) => ({
			...prev,
			phases: prev?.phases?.filter((_, i) => i !== index),
		}));
	};

	const onSuccess = (): void => {
		notify.success('Proiect adăugat cu succes!');
		dispatch(projectActions.setId(undefined));
		navigate(`/common/project?fit=${FIT_ONLY.PROJECT}`);
	};

	const handleCreate = (): void => {
		// Check for empty phases
		if (hasPhases && data?.phases) {
			const hasEmptyPhase = hasEmptyOrNull(data.phases);
			if (hasEmptyPhase) {
				notify.warning('Toate câmpurile din fiecare etapă trebuie completate.');
				return;
			}
		}

		// Check phase description for length and meaningful content
		const hasInvalidPhaseDescription = data?.phases?.some(phase => {
			const strippedDescription = phase?.description?.replace(/<[^>]+>/g, '').trim();
			return !strippedDescription || strippedDescription.length === 0;
		});

		if (hasInvalidPhaseDescription) {
			notify.warning('Fiecare etapă trebuie să conțină o descriere validă.');
			return;
		}

		const sendData: ICreateProjectSendData = {
			description: data.description,
			end: data.end,
			name: data.name,
			start: data.start,
			status: data.status,
			stage: data.stage,
			type: data?.type?._id,
			elements: data.elements,
			gallery: data.gallery,
			...(data.phases && hasPhases && {
				// eslint-disable-next-line
				phases: data.phases.map(({ id, ...rest }) => rest)
			})
		};

		createProject(sendData, onSuccess);
	};

	const onSuccessUpdate = (): void => {
		notify.success('Proiect actualizat cu succes!');
		dispatch(projectActions.setId(projectId));
		navigate(`/common/project?fit=${FIT_ONLY.PROJECT}`);
	};

	const handleEdit = (): void => {
		if (hasPhases && data?.phases) {
			const hasEmptyPhase = hasEmptyOrNull(data.phases);
			if (hasEmptyPhase) {
				notify.warning('Toate câmpurile din fiecare etapă trebuie completate.');
				return;
			}
		}

		// Check phase description for length and meaningful content
		const hasInvalidPhaseDescription = data?.phases?.some(phase => {
			const strippedDescription = phase?.description?.replace(/<[^>]+>/g, '').trim();
			return !strippedDescription || strippedDescription.length === 0;
		});

		if (hasInvalidPhaseDescription) {
			notify.warning('Fiecare etapă trebuie să conțină o descriere validă.');
			return;
		}

		const sendData: ICreateProjectSendData = {
			description: data.description,
			end: data.end,
			name: data.name,
			start: data.start,
			status: data.status,
			stage: data.stage,
			type: data?.type?._id,
			gallery: data.gallery,
			elements: data.elements,
			...(data.phases && hasPhases && {
				// eslint-disable-next-line
				phases: data.phases.map(({ id, ...rest }) => rest)
			})
		};
		projectId && updateProject(projectId, sendData, onSuccessUpdate);
	};

	const checkProjectName = (params: ICheckProjectName, onSuccessParam?: () => void, onError?: () => void): void => {
		handleName(params, onSuccessParam, onError, user?.role === 'superadmin' ? projData?.fsp?._id : undefined);
	};

	const contextValue: CreateProjectContextType = useMemo(() => ({
		step,
		setStep,
		data,
		setData,
		hasPhases,
		setHasPhases,
		isDrawing,
		setIsDrawing,
		handleAddPhase,
		handleRemovePhase,
		handleCreate,
		isEditing,
		handleEdit,
		disbleEditing,
		isLoading,
		checkProjectName,
		nameError,
		setNameErroer,
		checkProjectGeometry,
		interestFsp,
		setInterestFsp
		// eslint-disable-next-line
	}), [
		step, data, hasPhases, isDrawing, isEditing,
		setData, disbleEditing, isLoading, nameError, interestFsp
	]);

	return (
		<CreateProjectContext.Provider value={contextValue}>
			{children}
		</CreateProjectContext.Provider>
	);
};
