import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

export const MinimalLayout: FC<BoxProps> = styled(Box as FC<BoxProps>)(() => ({
	width: '100%',
	height: 'calc(var(--vh, 1vh) * 100)',

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
