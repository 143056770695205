import {
	Button, Checkbox, CircularProgress, FormControlLabel,
	FormGroup, Grid, TextField, useMediaQuery, useTheme
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '../flex';
import { SimpleDialogTransition } from '../simple-dialog-transition';
import { IRestrictionsProps } from './types';
import { IUserRestrict, useRestrictions } from '@/hooks/fetch-hooks';
import { useAuthContext } from '@/providers';
import { notify } from '@/utils';

interface DateTimeInfo {
	dateValue: string;
	timeValue: string;
}
interface FormState {
	dateValue?: string;
	timeValue?: string;
	isInfinite: boolean;
	reason: string;
}

export const ChangeRestrictionsModal: FC<IRestrictionsProps> = ({
	isBlocked,
	statement,
	setUser,
	buttonProps
}) => {
	const theme = useTheme();
	const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down(769));
	const [open, setOpen] = useState(false);
	const [formState, setFormState] = useState<FormState>({
		isInfinite: statement.isIndefinite,
		dateValue: statement.date,
		timeValue: statement.time,
		reason: statement.reason,
	});

	const { blockUser, loading, muteUser } = useRestrictions();
	const { user } = useAuthContext();
	const { id } = useParams<{ id: string }>();
	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const calculateDuration = (dateTime: DateTimeInfo): number => {
		const dateTimeString = `${dateTime.dateValue} ${dateTime.timeValue}`;

		const dateTimeObj = dayjs(dateTimeString, 'DD.MM.YYYY hh:mm A');
		if (!dateTimeObj.isValid()) {
			console.error('Invalid dateTimeString:', dateTimeString);
		}

		const timestamp = dateTimeObj.valueOf();
		return timestamp;
	};

	const handleClose = (): void => {
		setOpen(false);
		setFormState({
			isInfinite: statement.isIndefinite,
			dateValue: statement.date,
			timeValue: statement.time,
			reason: statement.reason,
		});
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { checked } = event.target;
		setFormState(prevState => ({ ...prevState, isInfinite: checked }));
	};

	const handleDateChange = (newValue: dayjs.Dayjs | null): void => {
		setFormState(prevState => ({
			...prevState,
			dateValue: newValue ? newValue.format('DD.MM.YYYY') : undefined,
		}));
	};

	const handleTimeChange = (newValue: dayjs.Dayjs | null): void => {
		setFormState(prevState => ({
			...prevState,
			timeValue: newValue ? newValue.format('hh:mm A') : undefined,
		}));
	};

	const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setFormState(prevState => ({
			...prevState,
			reason: event.target.value,
		}));
	};

	const handleBlockSuccess = (restrictObj: IUserRestrict): void => {
		setUser((prev) => ({
			...prev,
			status: 'blocked',
			blockMuteHistory: [
				{
					event: 'block',
					details: {
						at: dayjs().valueOf(),
						author: `${user?.firstName} ${user?.lastName}`,
						role: user?.role,
						reason: restrictObj.reason,
						until: restrictObj.until
					}
				},
				...(prev?.blockMuteHistory ? prev.blockMuteHistory : [])
			],
			blocked: true,
		}));
		notify.success('Utilizator blocat');
		setOpen(false);
	};

	const handleBlock = (): void => {
		const until = !formState.isInfinite && formState?.dateValue && formState?.timeValue ? calculateDuration({
			dateValue: formState?.dateValue, timeValue: formState?.timeValue
		}) : 0;

		const restrictObject = {
			at: dayjs().valueOf(),
			author: `${user?.firstName} ${user?.lastName}`,
			reason: formState.reason,
			until
		};
		id && blockUser(
			id,
			{ reason: restrictObject.reason, until: restrictObject.until },
			() => handleBlockSuccess(restrictObject)
		);
	};

	const handleMuteSuccess = (restrictObj: IUserRestrict): void => {
		setUser((prev) => ({
			...prev,
			status: prev?.status === 'blocked' ? prev?.status : 'muted',
			blockMuteHistory: [
				{
					event: 'mute',
					details: {
						at: dayjs().valueOf(),
						author: `${user?.firstName} ${user?.lastName}`,
						role: user?.role,
						reason: restrictObj.reason,
						until: restrictObj.until
					}
				},
				...(prev?.blockMuteHistory ? prev.blockMuteHistory : [])
			],
			muted: true,
		}));
		notify.success('Utilizator restricționat');
		setOpen(false);
	};

	const handleMute = (): void => {
		const until = !formState.isInfinite && formState?.dateValue && formState?.timeValue ? calculateDuration({
			dateValue: formState?.dateValue, timeValue: formState?.timeValue
		}) : 0;

		const restrictObject = {
			at: dayjs().valueOf(),
			author: `${user?.firstName} ${user?.lastName}`,
			reason: formState.reason,
			until,
		};

		id && muteUser(
			id,
			{ reason: restrictObject.reason, until: restrictObject.until },
			() => handleMuteSuccess(restrictObject)
		);
	};

	return isBlocked ? (
		<>
			<Button sx={{ height: '42px' }} onClick={handleClickOpen} {...buttonProps} >
				Editează restricția
			</Button>
			<SimpleDialogTransition open={open} setOpen={setOpen} title={'Editează perioada de blocare'}
				ctaSection={<>
					<Button variant='outlined' onClick={handleClose}>Anulează</Button>
					<Button variant='contained' onClick={handleBlock}>Salvează</Button>
				</>}
			>
				{loading ?
					<Flex width={'100%'} justifyCenter sx={{ minWidth: isMobileOrSmaller ? '100%' : '400px' }}>
						<CircularProgress />
					</Flex> :
					<Flex width={'100%'} sx={{ minWidth: '400px', maxWidth: '480px' }} column>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ro'>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox checked={formState.isInfinite}
												onChange={handleCheckboxChange} />}
											label="Permanent"
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={7}>
									<DatePicker
										label="Data"
										sx={{ width: '100%' }}
										value={formState.dateValue ? dayjs(formState.dateValue, 'DD.MM.YYYY') : null}
										onChange={handleDateChange}
										disabled={formState.isInfinite}
									/>
								</Grid>
								<Grid item xs={5}>
									<TimePicker
										label="Ora"
										value={formState.timeValue ? dayjs(formState.timeValue, 'hh:mm A') : null}
										onChange={handleTimeChange}
										disabled={formState.isInfinite}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Motivu de blocare"
										multiline
										maxRows={4}
										value={formState.reason}
										onChange={handleReasonChange}
										fullWidth
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Flex>
				}
			</SimpleDialogTransition>
		</>
	) : (
		<>
			<Button sx={{ height: '42px' }} onClick={handleClickOpen} {...buttonProps} >
				Editează restricția
			</Button>
			<SimpleDialogTransition open={open} setOpen={setOpen} title={'Editează perioada de restricționare'}
				ctaSection={<>
					<Button variant='outlined' onClick={handleClose}>Anulează</Button>
					<Button variant='contained' onClick={handleMute}>Salvează</Button>
				</>}
			>
				<Flex width={'100%'} sx={{ minWidth: '400px', maxWidth: isMobileOrSmaller ? '100%' : '480px' }} column>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ro'>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox checked={formState.isInfinite}
											onChange={handleCheckboxChange} />}
										label="Permanent"
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={7}>
								<DatePicker
									label="Data"
									sx={{ width: '100%' }}
									value={formState.dateValue ? dayjs(formState.dateValue, 'DD.MM.YYYY') : null}
									onChange={handleDateChange}
									disabled={formState.isInfinite}
								/>
							</Grid>
							<Grid item xs={5}>
								<TimePicker
									label="Ora"
									value={formState.timeValue ? dayjs(formState.timeValue, 'hh:mm A') : null}
									onChange={handleTimeChange}
									disabled={formState.isInfinite}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Motivul restricționării"
									multiline
									maxRows={4}
									value={formState.reason}
									onChange={handleReasonChange}
									fullWidth
								/>
							</Grid>
						</Grid>
					</LocalizationProvider>
				</Flex>
			</SimpleDialogTransition>
		</>
	);
};