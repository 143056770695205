import { AxiosError } from 'axios';
import { del, edit, get, post } from '@/utils';
import { IProjectType } from './types';

async function fetchTypes(
	page: number,
	items: number,
	onResponse?: (page: number, items: number) => void,
	getAll?: boolean,
	fspId?: string,
	owned?: boolean,
): Promise<IProjectType[]> {
	try {
		const queryLinkOwned = owned !== undefined ? `&owned=${owned}` : '';
		const queryLinkFsp = fspId ? `&fsp=${fspId}${queryLinkOwned}` : `${queryLinkOwned}`;
		const pageVariable = getAll ? 'all' : page + 1;
		// eslint-disable-next-line
		const res = await get(`api/protected/project-types?page=${pageVariable}&items=${items}${queryLinkFsp}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { projectTypes: IProjectType[] };
		return data?.projectTypes || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm tipurile de proiect.');
	}
}

async function fetchTypesQuery(
	query: string,
	page: number,
	items: number,
	onResponse?: (page: number, items: number) => void,
	getAll?: boolean,
	fspId?: string,
	owned?: boolean
): Promise<IProjectType[]> {
	try {
		const queryLinkOwned = owned !== undefined ? `&owned=${owned}` : '';
		const queryLinkFsp = fspId && !query.includes('fsp') ? `&fsp=${fspId}${queryLinkOwned}` : `${queryLinkOwned}`;
		const pageVariable = getAll ? 'all' : page + 1;
		// eslint-disable-next-line
		const res = await get(`api/protected/project-types${query}&page=${pageVariable}&items=${items}${queryLinkFsp}`);
		onResponse && onResponse(res.data?.pages, res?.data?.totalItems);
		const data = res.data as unknown as { projectTypes: IProjectType[] };
		return data?.projectTypes || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm tipurile de proiect.');
	}
}

async function deleteType(typeId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/project-types/${typeId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem tipul de proiect.');
	}
}

async function postTypes(
	typeObj: { name: string },
	onSuccessResponse?: (res: IProjectType) => void
): Promise<string | number> {
	try {
		const res = await post('api/protected/project-types', { ...typeObj });
		onSuccessResponse && res?.status === 201 && onSuccessResponse(res?.data?.projectType as IProjectType);
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să adăugăm tipul de proiect.');
	}
}

async function patchTypes(typeId: string, updateObj: { name: string }): Promise<string | number> {
	try {
		const res = await edit(`api/protected/project-types/${typeId}`, { ...updateObj });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm tipul de proiect.');
	}
}

export { fetchTypes, deleteType, patchTypes, postTypes, fetchTypesQuery };